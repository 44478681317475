/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from "react";
import Back from "../components/Back";
import { AllContext } from "../context/AllContext";

const Qna = () => {
  const { widthCh } = useContext(AllContext);
  const customWidth =
    widthCh !== "5px" && window.innerWidth <= 860
      ? `calc(100% - ${widthCh})`
      : "100%";
  const isLandscape = window.matchMedia("(orientation: landscape)").matches;
  const pcWidthCk =
    window.innerWidth >= 860 && widthCh !== "5px" ? `w-full` : `w-[50%]`;

  let videoIfameWidth;

  useEffect(() => {
    if (isLandscape) {
      console.log("가로 모드입니다.");
      // eslint-disable-next-line react-hooks/exhaustive-deps
      videoIfameWidth = customWidth;
    } else {
      console.log("세로 모드입니다.");
      videoIfameWidth = `100%`;
    }
  }, [isLandscape]);

  // 실시간 영상 오픈 관련
  const [isTimePassed, setIsTimePassed] = useState(false);

  useEffect(() => {
    const targetDate = new Date(2023, 8, 22, 14, 55); // Month is 0-based
    const checkTime = () => {
      const now = new Date();
      setIsTimePassed(now > targetDate);
    };

    checkTime();
    const interval = setInterval(checkTime, 1000); // Check every second

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <img
        src="./img/qnaBg.jpg"
        id="mainImg"
        className="relative h-full w-full "
        // width="2560"
        // height="1291"
      />
      <Back />
      <img
        src="./img/doctor_idle01.gif"
        loop="infinite"
        className="absolute z-20 bottom-[10%] left-[18%]  h-[150px]  lg:h-[40%] lg:left-[25%] lg:bottom-[10%] "
        id="qna_teacher"
      />
      <>
        <img
          src="./img/QNA_avatar_L.gif"
          loop="infinite"
          //className="   h-[115px]  lg:h-[30%] "
          className="absolute z-20 bottom-[4%] left-[8%]  h-[130px]  lg:h-[40%] lg:left-[5%] lg:bottom-0"
        />

        <img
          src="./img/QNA_avatar_C.gif"
          loop="infinite"
          //className="   h-[115px]  lg:h-[30%] lg:mt-[100px] mt-[25px]"
          className="absolute z-20  left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[130px] lg:h-[30%] bottom-[-15%]"
        />

        <img
          src="./img/QNA_avatar_R.gif"
          loop="infinite"
          //className="   h-[115px]  lg:h-[30%] "
          className="absolute z-20 bottom-[4%] right-[8%] h-[130px] lg:h-[40%] lg:right-[5%]  lg:bottom-0"
        />
      </>

      {/* 정중앙 유투브 */}
      <div
        className="flex justify-center items-center h-full w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain "
        style={{ width: isLandscape ? customWidth : videoIfameWidth }}
      >
        {/*
        {isTimePassed ? (
          <iframe
            className={`shadow-lg h-[50%] mt-[-7%] lg:mt-[-5%] w-[50%]`}
            src="https://www.youtube.com/embed/XEOcD4tk6Zk?si=-Mdaun4I8qloPUU9"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          ></iframe>
        ) : (
          <img
            src="./img/qna_video_temp.png"
            className={`shadow-lg h-[59%] mt-[7%]  lg:w-[50%] m-auto `}
            alt=""
          />
        )}
        */}

        <iframe
          className={`shadow-lg h-[50%] mt-[-7%] lg:mt-[-5%] w-[50%]`}
          src="https://www.youtube.com/embed/w6TdXrNV18o?si=1-F5hB-GNHCre5RR"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
        ></iframe>
      </div>
    </>
  );
};

export default Qna;
