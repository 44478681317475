/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import ImageMap from "image-map";
import Back from "../components/Back";
import YouTubeModal from "../components/YouTubeModal";

const Lecture = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [videoId, setVideoId] = useState("");

  const handleAreaClick = (videoId) => {
    setSelectedVideo(videoId);
  };

  const handleImageLoad = () => {
    ImageMap("img[usemap]");
  };

  return (
    <>
      <img
        src="./img/instBg.jpg"
        useMap="#image-map"
        id="mainImg"
        className="relative h-full w-full"
        onLoad={handleImageLoad}
        // width="2560"
        // height="1291"
      />

      <map name="image-map">
        <area
          target=""
          alt="전립선암 개요"
          title="전립선암 개요"
          onClick={() => handleAreaClick("video1")}
          coords="89,415,213,534,510,532,561,456,506,375,170,317"
          shape="poly"
          className="cursor-pointer"
        />

        <area
          target=""
          alt="전립선암의 진단"
          title="전립선암의 진단"
          onClick={() => handleAreaClick("video2")}
          coords="635,393,603,463,650,524,835,526,883,458,837,390"
          shape="poly"
          className="cursor-pointer"
        />
        <area
          target=""
          alt="전립선암의 치료"
          title="전립선암의 치료"
          onClick={() => handleAreaClick("video3")}
          coords="1105,387,1063,466,1102,526,1307,521,1344,454,1303,380,1254,380"
          shape="poly"
          className="cursor-pointer"
        />
        <area
          target=""
          alt="전립선암 수술 후 관리 "
          title="전립선암 수술 후 관리 "
          onClick={() => handleAreaClick("video4")}
          coords="1366,446,1381,507,1418,533,1696,535,1799,470,1819,380,1721,317,1551,339,1471,354,1404,375"
          shape="poly"
          className="cursor-pointer"
        />
      </map>

      <div className="w-full ">
        <img
          src="./img/lec01_avatar.gif"
          loop="infinite"
          className="absolute z-20 bottom-[4%] left-[15%] h-[180px] lg:h-[50%] "
        />

        <img
          src="./img/man05_idle01.gif"
          loop="infinite"
          className="absolute z-20 bottom-[4%] left-[28%] h-[180px] lg:h-[50%]"
        />

        <img
          src="./img/lec03_avatar.gif"
          loop="infinite"
          className="absolute z-30 bottom-[0%] right-[42%] h-[200px] lg:h-[48%] "
        />

        <img
          src="./img/lec04_avatar.gif"
          loop="infinite"
          className="absolute z-20 bottom-[4%] right-[25%] h-[185px] lg:h-[50%] "
        />

        <img
          src="./img/man02_idle01.gif"
          loop="infinite"
          className="absolute z-20 bottom-[0%] right-[5%] h-[200px] lg:h-[50%] "
        />
      </div>

      {selectedVideo && (
        <div
          className="fixed inset-0 flex items-center justify-center z-[999] video-Container flex-wrap "
          onClick={() => {
            setSelectedVideo(false);
          }}
        >
          {/* Embed YouTube video based on selectedVideo */}
          {selectedVideo === "video1" && (
            <div className="flex gap-3 justify-center w-full  items-center flex-wrap">
              <div className="my-1 w-full flex justify-center">
                <img src="./img/lec_title1.png" />
              </div>
              <img
                src="./img/lec1_1.jpg"
                alt="YouTube Thumbnail"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(true);
                  setVideoId("MJPGnywhQh0?si=2OYmPyTPXa1LUHnX");
                }}
                style={{ cursor: "pointer" }}
              />
              <iframe
                src="https://www.youtube.com/embed/ew0AoSQJ6CE?si=hVqI74azKcc39XlW"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              ></iframe>
              <iframe
                src="https://www.youtube.com/embed/0P6TJ-ZvljI?si=XRfS-YxREo0wVsg1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media;
                        gyroscope; picture-in-picture;
                        web-share; fullscreen"
                allowfullscreen
              ></iframe>

              <YouTubeModal
                isOpen={isOpen}
                videoId={videoId}
                onClose={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                }}
              />
              {/* <iframe
                className="w-1/3 shadow-lg"
                src="https://www.youtube.com/embed/MJPGnywhQh0?si=2OYmPyTPXa1LUHnX"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                onClick={(e) => {
                  e.stopPropagation(); // 이벤트 버블링 중단
                }}
              ></iframe> */}
            </div>
          )}
          {selectedVideo === "video2" && (
            <div className="flex gap-3 justify-center w-full  items-center flex-wrap">
              <div className="my-2 w-full flex justify-center">
                <img src="./img/lec_title2.png" />
              </div>
              <img
                src="./img/lec2_1.jpg"
                alt="YouTube Thumbnail"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(true);
                  setVideoId("5_f0U025swQ?si=owcuIpeaiZxBkUTD");
                }}
                style={{ cursor: "pointer" }}
              />
              <img
                src="./img/lec2_2.jpg"
                alt="YouTube Thumbnail"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(true);
                  setVideoId("wqCtCcs7woY?si=rrHxn6jXXXpLWJic");
                }}
                style={{ cursor: "pointer" }}
              />
              <YouTubeModal
                isOpen={isOpen}
                videoId={videoId}
                onClose={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                }}
              />
              {/* <iframe
                className="w-1/3 shadow-lg"
                src="https://www.youtube.com/embed/5_f0U025swQ?si=owcuIpeaiZxBkUTD"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                onClick={(e) => {
                  e.stopPropagation(); // 이벤트 버블링 중단
                }}
              ></iframe>
              <iframe
                className="w-1/3"
                src="https://www.youtube.com/embed/wqCtCcs7woY?si=rrHxn6jXXXpLWJic"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                onClick={(e) => {
                  e.stopPropagation(); // 이벤트 버블링 중단
                }}
              ></iframe> */}
            </div>
          )}
          {selectedVideo === "video3" && (
            <div className="flex gap-1 justify-around w-full items-center flex-wrap">
              <div className="my-2 w-full flex justify-center">
                <img src="./img/lec_title3.png" />
              </div>
              <div className="mx-10 w-full gap-2 flex justify-center">
                <img
                  src="./img/lec3_1.jpg"
                  alt="YouTube Thumbnail"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                    setVideoId("l6DFHkSQh-c?si=gwPZTZ5NBAwEqxF0");
                  }}
                  style={{ cursor: "pointer" }}
                  className="object-contain w-1/3 lg:object-none lg:w-auto"
                />
                <img
                  src="./img/lec3_2.jpg"
                  alt="YouTube Thumbnail"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                    setVideoId("8IWR2BKmZGY?si=7AHUAOKWh9xcOuK-");
                  }}
                  style={{ cursor: "pointer" }}
                  className="object-contain w-1/3 lg:object-none lg:w-auto"
                />
                <img
                  src="./img/lec3_3.jpg"
                  alt="YouTube Thumbnail"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                    setVideoId("XBaDEnUv6a4?si=KwM0pC9mTzrqsX4z");
                  }}
                  style={{ cursor: "pointer" }}
                  className="object-contain w-1/3 lg:object-none lg:w-auto"
                />
                <YouTubeModal
                  isOpen={isOpen}
                  videoId={videoId}
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsOpen(false);
                  }}
                />

                {/* <iframe
                  className="w-1/3 shadow-lg"
                  src="https://www.youtube.com/embed/l6DFHkSQh-c?si=gwPZTZ5NBAwEqxF0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  onClick={(e) => {
                    e.stopPropagation(); // 이벤트 버블링 중단
                  }}
                ></iframe>
                <iframe
                  className="w-1/3 shadow-lg"
                  src="https://www.youtube.com/embed/8IWR2BKmZGY?si=7AHUAOKWh9xcOuK-"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  onClick={(e) => {
                    e.stopPropagation(); // 이벤트 버블링 중단
                  }}
                ></iframe>
                <iframe
                  className="w-1/3 shadow-lg"
                  src="https://www.youtube.com/embed/XBaDEnUv6a4?si=KwM0pC9mTzrqsX4z"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  onClick={(e) => {
                    e.stopPropagation(); // 이벤트 버블링 중단
                  }}
                ></iframe> */}
              </div>
            </div>
          )}
          {selectedVideo === "video4" && (
            <div className="flex gap-1 justify-around w-full items-center flex-wrap">
              <div className="my-2 w-full flex justify-center">
                <img src="./img/lec_title4.png" />
              </div>
              <div className="px-[2em] w-full gap-2 flex justify-center">
                <img
                  src="./img/lec4_1.jpg"
                  alt="YouTube Thumbnail"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                    setVideoId("XpgS3WFl4B8?si=FkttCCaJd880Z86g");
                  }}
                  style={{ cursor: "pointer" }}
                  className="object-contain w-1/3 lg:object-none lg:w-auto"
                />
                <img
                  src="./img/lec4_2.jpg"
                  alt="YouTube Thumbnail"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                    setVideoId("5dZYo4LyKpg?si=KoxiUwK7_ESMBqw2");
                  }}
                  style={{ cursor: "pointer" }}
                  className="object-contain w-1/3 lg:object-none lg:w-auto"
                />
                <img
                  src="./img/lec4_3.jpg"
                  alt="YouTube Thumbnail"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(true);
                    setVideoId("By41yIyVhOE?si=MwpECW1F5Y-TDRq3");
                  }}
                  style={{ cursor: "pointer" }}
                  className="object-contain w-1/3 lg:object-none lg:w-auto"
                />
                <YouTubeModal
                  isOpen={isOpen}
                  videoId={videoId}
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsOpen(false);
                  }}
                />
                {/* <iframe
                  className="w-1/3 shadow-lg"
                  src="https://www.youtube.com/embed/XpgS3WFl4B8?si=FkttCCaJd880Z86g"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  onClick={(e) => {
                    e.stopPropagation(); // 이벤트 버블링 중단
                  }}
                ></iframe>

                <iframe
                  className="w-1/3 shadow-lg"
                  src="https://www.youtube.com/embed/5dZYo4LyKpg?si=KoxiUwK7_ESMBqw2"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  onClick={(e) => {
                    e.stopPropagation(); // 이벤트 버블링 중단
                  }}
                ></iframe>
                <iframe
                  className="w-1/3 shadow-lg"
                  src="https://www.youtube.com/embed/By41yIyVhOE?si=MwpECW1F5Y-TDRq3"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  onClick={(e) => {
                    e.stopPropagation(); // 이벤트 버블링 중단
                  }}
                ></iframe> */}
              </div>
            </div>
          )}
        </div>
      )}

      <Back />
    </>
  );
};
export default Lecture;
