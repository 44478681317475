/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import ImageMap from "image-map";
const Main = () => {
  const handleImageLoad = () => {
    ImageMap("img[usemap]");
  };

  return (
    <>
      <img
        src="./img/mainhall.jpg"
        useMap="#image-map"
        id="mainImg"
        className="relative h-full w-full "
        onLoad={handleImageLoad}
        // width="2560"
        // height="1291"
      />
      <map name="image-map">
        <area
          target="_self"
          alt="블루리본 캠페인"
          title="블루리본 캠페인"
          href="/desc"
          coords="257,203,111,359,152,780,449,673,440,368,432,246"
          shape="poly"
        />
        <area
          target=""
          alt="전립선암 건강강좌"
          title="전립선암 건강강좌"
          href="/lecture"
          coords="550,266,491,381,510,656,745,619,754,378,747,292"
          shape="poly"
        />
        <area
          target="_blank"
          alt="질문 게시판"
          title="질문 게시판"
          href="https://workspace.caitory.com/contents/BRC/board/?page_id=7"
          coords="820,283,777,378,759,831,1192,838,1132,370,1097,283"
          shape="poly"
        />
        <area
          target=""
          alt="실시간 QnA"
          title="실시간 QnA"
          href="/qna"
          coords="1176,280,1164,388,1161,627,1426,685,1434,385,1365,266"
          shape="poly"
        />
        <area
          target="_blank"
          alt="만족도 설문참여"
          title="만족도 설문참여"
          href="https://docs.google.com/forms/d/e/1FAIpQLSdEcGorFuU7rCHIJ8uqMKogLZhiDqBd1Zjof2v33YvM-aJTlw/viewform"
          coords="1490,251,1487,695,1806,846,1809,351,1644,200"
          shape="poly"
        />
      </map>

      <>
        <img
          src="./img/man01_idle01.gif"
          loop="infinite"
          className="absolute z-20 bottom-[10%] left-[5%] h-[200px] lg:h-[50%]"
        />
        <img
          src="./img/man02_idle01.gif"
          loop="infinite"
          className="absolute z-20 bottom-[2%] left-[20%] h-[200px] lg:left-[20%] lg:h-[50%] "
        />
        <img
          src="./img/man03_talk01.gif"
          loop="infinite"
          className="absolute z-30 bottom-[0%] right-[30%] h-[180px] lg:h-[45%] "
        />
        <img
          src="./img/man04_idle01.gif"
          loop="infinite"
          className="absolute z-20 bottom-[0%] right-[20%] h-[180px] lg:h-[45%] "
        />
        <img
          src="./img/man05_idle01.gif"
          loop="infinite"
          className="absolute z-20 bottom-[10%] right-[5%] h-[200px] lg:h-[50%] "
        />
      </>
    </>
  );
};

export default Main;
