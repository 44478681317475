/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const YouTubeModal = ({ isOpen, videoId, onClose }) => {
  if (!isOpen) return null;

  const videoSrc = `https://www.youtube.com/embed/${videoId}`;
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={onClose}
    >
      <iframe
        className="shadow-lg h-[95%] w-[95%]"
        src={videoSrc}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
      ></iframe>
    </div>
  );
};

export default YouTubeModal;
