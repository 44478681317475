import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ChatToggleComponent from "./components/ChatToggleComponent";
import { AllContext } from "./context/AllContext";
function App() {
  const navigate = useNavigate();
  const { widthCh, setWidthCh } = useContext(AllContext);

  // useEffect(() => {
  //   // 모바일 웹 주소창 숨기기
  //   window.addEventListener(
  //     "load",
  //     function() {
  //       // body의 height를 살짝 늘리는 코드
  //       document.body.style.height =
  //         document.documentElement.clientHeight + 5 + "px";
  //       // scroll를 제어하는 코드
  //       setTimeout(() => window.scrollTo(0, 1), 0);
  //     },
  //     false
  //   );
  // }, []);

  const [showPopup, setShowPopup] = useState(true); // 팝업을 보여줄지 여부를 결정하는 state

  // const [innerHeight, setInnerHeight] = useState(0);
  // const [innerWidth, setInnerWidth] = useState(0);

  // useEffect(() => {
  //   // 이전에 팝업을 확인한 기록이 있는지 확인

  //   const popupConfirmed = localStorage.getItem("popupConfirmed");
  //   if (popupConfirmed !== null) {
  //     setShowPopup(false);
  //   }

  //   // if (typeof window !== "undefined") {
  //   //   setInnerHeight(window.innerHeight);
  //   //   setInnerWidth(window.innerWidth);
  //   // }
  //   // console.log(innerHeight, "innerHeight");
  //   // console.log(innerWidth, "setInnerWidth");
  // }, []);

  const handleConfirm = () => {
    // if (screenfull.isEnabled) {
    //   screenfull
    //     .request()
    //     .then(() => {
    //       setShowPopup(false); // 풀스크린 모드로 전환 후 팝업 숨김
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //       setShowPopup(false); // 에러가 발생하면 팝업을 숨깁니다.
    //     });
    // }
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setShowPopup(false);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }

    // 팝업 확인 기록을 localStorage에 저장
    localStorage.setItem("popupConfirmed", "true");
  };

  const location = useLocation();

  // 모바일 100vh 맞추기
  const height_ = window.innerHeight;
  const width_ = window.innerWidth;

  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // 처음 마운트될 때 한 번 실행

    // 가로모드, 세로모드 바뀔 때 페이지를 새로고침하는 핸들러
    const handleOrientationChange = () => {
      window.location.reload();
    };

    // 이벤트 리스너를 추가
    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      window.removeEventListener("resize", handleResize);

      // 가로모드, 세로모드 바뀔 때 페이지를 새로고침하는 이벤트 리스너 제거
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  //const mobileStyle = isMobile ? { height: `${width}px` } : {};

  useEffect(() => {
    // 현재 URL이 '/chat'인지 확인
    if (location.pathname !== "/qna") {
      // '/chat'이 아닐 경우, overflow: hidden을 적용
      document.documentElement.classList.add("overflowHidden");
      document.querySelector(".App").classList.add("overflowHidden");
    } else {
      // '/chat'일 경우, overflow: hidden을 제거
      // document.documentElement.classList.add("overflowHidden");
      // document.querySelector(".App").classList.add("overflowHidden");
      // document.documentElement.classList.remove("overflowHidden");
      // document.querySelector(".App").classList.remove("overflowHidden");
    }
    console.log(
      "기기높이",
      height_,
      "기기넓이",
      width_,
      "함수 테스트",
      //mobileStyle,
      height
    );
    // 컴포넌트가 unmount 될 때 overflow: hidden을 제거하도록 설정
    return () => {
      document.documentElement.classList.remove("overflowHidden");
      document.querySelector(".App").classList.remove("overflowHidden");
    };
  }, [location]);

  const customWidth =
    widthCh !== "5px" && location.pathname === "/qna"
      ? `calc(100% - ${widthCh})`
      : `100%`;

  console.log(
    showPopup,
    location.pathname === "/",
    showPopup && location.pathname === "/"
  );

  return (
    <>
      <div
        className="relative"
        id="App-first-container"

        // style={{ height: `${innerHeight}px`, width: `{innerWidth}px` }}
      >
        <div className="App">
          <Outlet />
        </div>
      </div>
      {/* {showPopup && location.pathname === "/" && (
        <div className="z-[99999] p-6 bg-white rounded-lg shadow-xl absolute flex justify-center flex-wrap top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <p className="text-lg mb-4 text-center w-full">
            풀스크린 모드로 전환하시겠습니까?
          </p>
          <button
            onClick={handleConfirm}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-700"
          >
            확인
          </button>
          <button
            onClick={() => {
              localStorage.setItem("popupConfirmed", "false");
              setShowPopup(false);
            }}
            className="bg-slate-500 text-white px-4 py-2 rounded hover:bg-slate-600 focus:outline-none focus:bg-blue-700 mx-2"
          >
            아니오
          </button>
        </div>
      )} */}
      {location.pathname === "/qna" && (
        <ChatToggleComponent setWidthCh={setWidthCh} />
      )}
    </>
  );
}

export default App;
