import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Chat from "./Chat";
import Chat2 from "./Chat2";
import Main from "./pages/Main";
import Desc from "./pages/Desc";
import Lecture from "./pages/Lecture";
import Board from "./pages/Board";
import Qna from "./pages/Qna";
import Survey from "./pages/Survey";
import { AllProvider } from "./context/AllContext";
import Temporary from "./pages/Temporary";

// 2023년 9월 15일 오후 3시 54분 오픈
const currentDate = new Date();
const switchDate = new Date(2023, 8, 15, 15, 54); // JavaScript의 month는 0부터 시작하므로 8은 9월을 의미합니다.
const shouldShowTemporary = currentDate > switchDate;

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <div>Error Page 😭</div>,
    children: [
      {
        index: true,
        path: "/",
        element: shouldShowTemporary ? <Temporary /> : <Main />,
      },
      {
        path: "/desc",
        element: <Desc />,
      },
      {
        path: "/lecture",
        element: <Lecture />,
      },
      {
        path: "/board",
        element: <Board />,
      },
      {
        path: "/qna",
        element: <Qna />,
      },
      {
        path: "/survey",
        element: <Survey />,
      },
      {
        path: "/chat",
        element: <Chat />,
      },
      {
        path: "/chat2",
        element: <Chat2 />,
      },
      {
        path: "/test",
        element: <Main />,
      },
    ],
  },
]);

root.render(
  <AllProvider>
    <RouterProvider router={router} />
  </AllProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
