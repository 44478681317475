/* eslint-disable jsx-a11y/alt-text */
import React from "react";

const Temporary = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <img src="./img/end_10-20.jpg" className="" />
    </div>
  );
};

export default Temporary;
