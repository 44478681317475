/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import Back from "../components/Back";
import ImageMap from "image-map";

function Desc() {
  const [content, setContent] = useState(1); // 기본값은 desc_cont1

  const handleRightClick = () => {
    if (content < 3) {
      // 예를 들어 3개의 내용이 있다고 가정
      setContent(content + 1);
    }
  };

  const handleLeftClick = () => {
    if (content > 1) {
      setContent(content - 1);
    }
  };

  // useEffect(() => {
  //   ImageMap("img[usemap]"); //ImageMap 삽입코드
  // }, []);
  const handleImageLoad = () => {
    ImageMap("img[usemap]");
  };

  return (
    <>
      <img
        src="./img/descBg.jpg"
        id="mainImg"
        className="relative h-full w-full "
        // width="2560"
        // height="1291"
      />
      <Back />

      {/* <img
        src="./img/desc_cont1.png"
        usemap="#image-map1"
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      />

      <map name="image-map1">
        <area
          target=""
          alt="page1_right"
          title="page1_right"
          href=""
          coords="1204,215,1195,239,1231,281,1197,334,1217,348,1280,279,1227,223"
          shape="poly"
        />
        <area
          target=""
          alt="page1_left"
          title="page1_left"
          href=""
          coords="90,215,107,227,66,282,102,321,103,338,90,345,30,287"
          shape="poly"
        />
      </map> */}

      {content === 1 && (
        <img
          src="./img/desc_cont1.png"
          useMap="#image-map1"
          className="desc-content absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain w-[65%]"
          onLoad={handleImageLoad}
          // width="1300"
          // height="561"
        />
      )}
      {content === 2 && (
        <img
          src="./img/desc_cont2.png"
          useMap="#image-map1"
          className="desc-content absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain w-[65%]"
          onLoad={handleImageLoad}
          // width="1300"
          // height="561"
        />
      )}
      {content === 3 && (
        <img
          src="./img/desc_cont3.png"
          useMap="#image-map1"
          className="desc-content absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain w-[65%]"
          onLoad={handleImageLoad}
          // width="1300"
          // height="561"
        />
      )}

      <map name="image-map1">
        <area
          alt="page1_right"
          title="page1_right"
          onClick={handleRightClick}
          coords="1092,0,1298,558"
          shape="rect"
          className="cursor-pointer"
        />
        <area
          alt="page1_left"
          title="page1_left"
          onClick={handleLeftClick}
          coords="190,560,1,0"
          shape="rect"
          className="cursor-pointer"
        />
      </map>
    </>
  );
}

export default Desc;
