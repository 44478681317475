import React, { useState, useEffect, useRef } from "react";

const ChatToggleComponent = ({ setWidthCh }) => {
  const [showIframe, setShowIframe] = useState(true);
  const [buttonRight, setButtonRight] = useState("0px");

  const iframeRef = useRef(null);

  useEffect(() => {
    if (showIframe && iframeRef.current) {
      const iframeWidth = iframeRef.current.offsetWidth;
      setButtonRight(iframeWidth + "px");
      setWidthCh(iframeWidth + "px");
    } else {
      setButtonRight("10%");
      setWidthCh("5px");
    }
  }, [showIframe]);

  return (
    <div>
      <div
        className="h-full absolute z-30 bottom-0 flex justify-center items-center"
        style={{ right: buttonRight }}
      >
        <button
          onClick={() => setShowIframe(!showIframe)}
          className="w-[70px]  h-[50px] 
               bg-blue-500 text-white font-semibold px-1 text-center rounded hover:bg-blue-700 
               active:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition ease-in-out duration-150 break-keep"
        >
          {showIframe ? "채팅방 닫기" : "채팅방 열기"}
        </button>
      </div>

      {showIframe && (
        <div className="lg:w-[20%] h-full absolute z-20 bottom-0 right-0 flex justify-center align-middle items-center">
          <iframe
            ref={iframeRef}
            src="https://brc.caitory.com/mini-chat/index.html"
            width="100%"
            height="100%"
            frameBorder="0"
            scrolling="auto"
            title="Mini Chat"
            style={{ border: "none" }}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default ChatToggleComponent;
