import React from "react";
import { AiFillHome } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function Back() {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate("/test");
      }}
      className="w-[3.5em] h-[3.5em] lg:w-[4.5em] lg:h-[4.5em] rounded-full bg-gray-600 hover:bg-gray-800 absolute top-5 cursor-pointer left-5 z-50 flex justify-center items-center shadow-lg"
    >
      <AiFillHome className="text-[2em] lg:text-[2em]  text-white" />
    </div>
  );
}

export default Back;
